const linkResolver = ({ node, key, value }) => doc => {
  if (doc.lang === 'en-gb') {
    if (doc.uid !== 'index' && doc.type === 'page') {
      return `/${doc.uid}`
    }
    return `/`
  } else {
    if (doc.uid !== 'index' && doc.type === 'page') {
      return `/${doc.lang.slice(0, 2)}/${doc.uid}`
    }
    return `/${doc.lang.slice(0, 2)}/`
  }
}

module.exports = linkResolver
